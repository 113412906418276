import ConfigRoutes from "../../config/Routes"
import DashboardIndex from "../controllers/DashboardIndex";
import PermissionsIndex from "../controllers/PermissionsIndex";
import AdminsIndex from "../controllers/AdminsIndex";
import AdminsEdit from "../controllers/AdminsEdit";
import AdminsNew from "../controllers/AdminsNew";
import RoleIndex from "../controllers/RoleIndex";
import RoleEdit from "../controllers/RoleEdit";
import ProfileIndex from "../controllers/ProfileIndex";
import AdminMenuIndex from "../controllers/AdminMenuIndex";
import AdminMenuForm from "../controllers/AdminMenuForm";

export default class Routes extends ConfigRoutes{

    static routes = {
        "DashboardIndex": DashboardIndex,
        "PermissionsIndex": PermissionsIndex,
        "AdminsIndex": AdminsIndex,
        "AdminsEdit": AdminsEdit,
        "AdminsUpdate": AdminsEdit,
        "AdminsNew": AdminsNew,
        "AdminsCreate": AdminsNew,
        "RolesIndex": RoleIndex,
        "RolesEdit": RoleEdit,
        "RolesNew": RoleEdit,
        "RolesUpdate": RoleEdit,
        "RolesCreate": RoleEdit,
        "ProfileIndex": ProfileIndex,
        "AdminMenusIndex": AdminMenuIndex,
        "AdminMenusNew": AdminMenuForm,
        "AdminMenusCreate": AdminMenuForm,
        "AdminMenusEdit": AdminMenuForm,
        "AdminMenusUpdate": AdminMenuForm,
    };

    constructor() {
        super();
        this.routes = Object.assign(this.routes, Routes.routes);
    }
}